<template>
  <Suspense>
    <div class="flex justify-center mb-4 lg:justify-start">
      <div class="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
        <h5 class="flex gap-2 items-center justify-between mb-4 text-xl font-medium text-gray-500 dark:text-gray-400">
          {{ humanizePlan(selectedPlan.name) }}
          <button
            type="button"
            class="btn btn-small btn-white gap-2"
            @click="togglePlanModal">
            <i class="far fa-pencil cursor-pointer" />
            Change plan
          </button>
        </h5>
        <div class="flex items-baseline text-gray-900 dark:text-white">
          <span class="text-5xl font-extrabold tracking-tight">
            {{ selectedPlan.amountWithCurrency }}
          </span>
          <span class="ml-1 text-xl font-normal text-gray-500 dark:text-gray-400">
            /{{ selectedPlan.interval }}
          </span>
        </div>

        <ul
          role="list"
          class="space-y-2 my-4">
          <li
            v-for="(item, index) in planFeatures"
            :key="index"
            class="flex space-x-3">
            <i class="fas fa-check-circle flex-shrink-0 w-5 h-5 text-blue-600 dark:text-blue-500 ml-1" />
            <span class="text-base font-normal leading-tight text-gray-500 dark:text-gray-400">{{ item }}</span>
          </li>
        </ul>
      </div>
      <UiModal
        id="plans"
        ref="plansModal">
        <template #modal-header-text>
          Change plan
        </template>
        <template #modal-body>
          <Multiselect
            v-model="newPlan"
            :options="planOptions"
            :multiple="false"
            :close-on-select="true"
            :show-labels="false"
            :allow-empty="false"
            placeholder="Select Plan"
            label="name"
            track-by="name">
            <template #singleLabel="{ option }">
              <span class="uppercase">{{ humanizePlan(option.name) }}</span>
            </template>
            <template #option="{ option }">
              <span class="uppercase">{{ humanizePlan(option.name) }}</span>
            </template>
          </Multiselect>

          <div v-if="newPlan">
            <div class="flex items-baseline text-gray-900 dark:text-white">
              <span class="text-base font-extrabold tracking-tight">
                {{ newPlan.amountWithCurrency }}
              </span>
              <span class="ml-1 text-base font-normal text-gray-500 dark:text-gray-400">
                /{{ newPlan.interval }}
              </span>
            </div>
          </div>
        </template>
        <template #modal-footer>
          <a
            :href="`/clients/sign_up?plan=${newPlan?.name}`"
            type="button"
            class="btn btn-primary btn-small">
            Change
          </a>
          <button
            type="button"
            class="btn btn-default btn-small"
            @click="plansModal.hide">
            Cancel
          </button>
        </template>
      </UiModal>
    </div>
  </Suspense>
</template>

<script setup>
import { ref } from 'vue'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import { UiModal } from '~/vue_widgets/components/ui/modal'
import axios from '~/init/axios.js'

const props = defineProps({
  selectedPlan: { type: Object, required: true }
})

const humanizePlan = (planName) => planName.replace('_', ' ')

const planFeatures = [
  'Unlimited team members',
  'Location Visbility Heatmaps',
  'Advanced Location Analytics',
  'Reputation Generation Campaigns',
  '80+ Platform Rep Monitoring',
  'Website Review Display Widgets',
  'Website Live Chat',
  'GBP Call Tracking',
  'Neighborhood Marketing Tech',
  'GBP Post & Media Scheduler',
  'Website Live Chat',
  'Enhanced Business Listings',
  'Smart Followup System'
]
const plansModal = ref(null)
const planOptions = ref([])
const newPlan = ref(null)

const togglePlanModal = () => {
  plansModal.value.toggle()
}

const getPlanOptions = async() => {
  planOptions.value = (await axios.get('/ajax/plans')).data
  newPlan.value = planOptions.value.find(({ name }) => name === props.selectedPlan.name)
}
getPlanOptions()
</script>
