<template>
  <div class="">
    <div class="">
      <span> Search Customers</span>
      <input
        v-model="search"
        type="text"
        class="form-control mb-5"
        style="width: auto"
        autofocus>

      <span>
        <input
          v-model="showDeleted"
          type="checkbox">
        Hide deleted organizations
      </span>

      <VueSlimTables
        ref="table"
        :source="tableSource"
        :columns="columns"
        class="table w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 "
        :per-page="perPage">
        <template #cell:customer="{ row }">
          <div class="flex flex-col space-y-2">
            <div class="font-semibold text-lg">
              <a :href="`/organizations/${row.id}`">{{ row.name || 'N/A' }}</a>
            </div>
            <div class="text-sm text-gray-600">
              {{ row.owner }}
            </div>
            <div class="flex items-center space-x-2">
              <span class="font-medium text-sm">Plan:</span>
              <span :class="getPlanBadgeClass(row.planName)">{{ row.planName || 'Undefined' }}</span>
            </div>
            <div class="flex items-center space-x-2">
              Trello:
              <div v-if="row.trelloCustomerCardCreatedAt" class="flex items-center space-x-2">
                <span class="ml-2">Connected {{ row.trelloCustomerCardCreatedAt }}</span>
                <a
                  v-if="row.trelloCustomerCardId"
                  :href="`https://trello.com/c/${row.trelloCustomerCardId}`"
                  target="_blank"
                  class="text-blue-500 hover:text-blue-700"
                  title="Customer Card"
                >
                  <i class="fas fa-user"></i>
                </a>
                <a
                  v-if="row.trelloActivationCardId"
                  :href="`https://trello.com/c/${row.trelloActivationCardId}`"
                  target="_blank"
                  class="text-blue-500 hover:text-blue-700"
                  title="Activation Card"
                >
                  <i class="fas fa-bolt"></i>
                </a>
                <a
                  v-if="row.trelloLaunchCardId"
                  :href="`https://trello.com/c/${row.trelloLaunchCardId}`"
                  target="_blank"
                  class="text-blue-500 hover:text-blue-700"
                  title="Launch Card"
                >
                  <i class="fas fa-rocket"></i>
                </a>
                <a
                  v-if="row.trelloExecutionCardId"
                  :href="`https://trello.com/c/${row.trelloExecutionCardId}`"
                  target="_blank"
                  class="text-blue-500 hover:text-blue-700"
                  title="Execution Card"
                >
                  <i class="fas fa-play"></i>
                </a>
                <a
                  v-if="row.trelloPerformanceCardId"
                  :href="`https://trello.com/c/${row.trelloPerformanceCardId}`"
                  target="_blank"
                  class="text-blue-500 hover:text-blue-700"
                  title="Performance Card"
                >
                  <i class="fas fa-chart-simple"></i>
                </a>
              </div>
              <div v-else>
                <div v-if="loadingTrelloId === row.id" class="flex items-center text-blue-500">
                  <i class="fas fa-spinner fa-spin mx-2"></i>
                  <span>Initiating...</span>
                </div>
                <a
                  v-else
                  @click.prevent="initiateTrello(row.id)"
                  class="text-blue-500 ml-2 hover:cursor-pointer hover:underline"
                >
                  Initiate Trello
                </a>
              </div>
            </div>

            <div class="text-sm">
              <span class="font-medium">Customer for:</span> {{ calculateCustomerDuration(row.createdAt) }}
            </div>
          </div>
        </template>

        <template #cell:statuses="{ row }">
          <div class="flex flex-col space-y-2">
            <div>
              <span class="text-xs font-medium mr-2">Onboarding:</span>
              <span
                class="hover:underline hover:cursor-pointer"
                :class="getOnboardingBadgeClass(row.onboardingState)"
                @click="viewOnboardingNotes(row.id, row.name || 'N/A')">
                {{ formatOnboardingState(row.onboardingState) }}
              </span>
            </div>
            <div>
              <span class="text-xs font-medium mr-2">Provisioning:</span>
              <span :class="getProvisioningBadgeClass(row)">
                {{ formatProvisioningStatus(row) }}
              </span>
            </div>
            <div class="flex items-center">
              <span class="text-xs font-medium mr-2">Sentiment:</span>
              <span :class="getSentimentBadgeClass(row.sentiment)">
                {{ formatSentiment(row.sentiment) }}
              </span>
              <button @click="toggleSentimentDropdown(row)" class="ml-2 text-gray-500 hover:text-gray-700">
                <i class="fas fa-edit"></i>
              </button>
            </div>
            <div v-if="row.showSentimentDropdown" class="relative">
              <select
                v-model="row.sentiment"
                @change="updateSentiment(row)"
                class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="pending">Pending</option>
                <option value="happy">Happy</option>
                <option value="ok">OK</option>
                <option value="upset">Upset</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>

             <!-- New Order Submitted field -->
            <div class="flex items-center">
              <span class="text-xs font-medium mr-2">Order Submitted:</span>
              <span :class="getOrderSubmittedBadgeClass(row.orderSubmitted)">
                {{ formatOrderSubmitted(row.orderSubmitted) }}
              </span>
              <button @click="toggleOrderSubmittedDropdown(row)" class="ml-2 text-gray-500 hover:text-gray-700">
                <i class="fas fa-edit"></i>
              </button>
            </div>
            <div v-if="row.showOrderSubmittedDropdown" class="relative">
              <select
                v-model="row.orderSubmitted"
                @change="updateOrderSubmitted(row)"
                class="block appearance-none w-full bg-white border border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              >
                <option :value="false">False</option>
                <option :value="true">True</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
              </div>
            </div>
          </div>
        </template>

        <template #cell:moments="{ row }">
          <div class="flex flex-col space-y-2 text-xs">
            <div class="flex justify-between">
              <div
                v-for="(count, period) in row.momentCounts"
                :key="period"
                class="text-center px-1">
                <div class="font-bold">
                  {{ formatPeriod(period) }}
                </div>
                <div>{{ count }}</div>
              </div>
            </div>
            <hr class="border-t border-gray-300 my-1">
            <div :class="getLastMomentClass(row.timeSinceLastMoment)">
              Last: {{ formatTimeSinceLastMoment(row.timeSinceLastMoment) }}
            </div>
          </div>
        </template>

        <template #cell:features="{ row }">
          <div class="flex flex-col space-y-2">
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                :checked="row.featureLocations"
                @change="updateFeature(row.id, 'feature_locations', $event.target.checked)">
              <span class="ml-2">Location Management</span>
            </label>
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                :checked="row.featureChat"
                @change="updateFeature(row.id, 'feature_chat', $event.target.checked)">
              <span class="ml-2">Chat Management</span>
            </label>
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                :checked="row.featureLeadManagement"
                @change="updateFeature(row.id, 'feature_lead_management', $event.target.checked)">
              <span class="ml-2">Lead Management</span>
            </label>
            <label class="inline-flex items-center">
              <input
                type="checkbox"
                :checked="row.featureReputationManagement"
                @change="updateFeature(row.id, 'feature_reputation_management', $event.target.checked)">
              <span class="ml-2">Reputation Management</span>
            </label>
          </div>
        </template>

        <template #cell:actions="{ row }">
          <div class="flex space-x-2">
            <a
              :href="`/organizations/${row.id}`"
              title="View">
              <i class="fas fa-eye" />
            </a>
            <button
        @click="openNotesSlideout(row.id)"
        title="Notes">
        <i class="fas fa-sticky-note" />
      </button>
          </div>
        </template>
      </VueSlimTables>
    </div>
  </div>
</template>

<!-- eslint-disable no-console-->
<script>
import qs from 'qs'
import VueSlimTables from 'vue-slim-tables'
import mdtoast from '@dmuy/toast'
import axios from '../init/axios'
import 'vue-slim-tables/stylesheets'
import { runSwal } from '../common/delete_with_swal'
import '@dmuy/toast/dist/mdtoast.css'
import useSlidePanel from '@/vue_widgets/composables/use_slide_panel'
import OnboardingNotesComponent from './onboarding_notes.vue'
import OrganizationNotes from './organization_notes.vue'  // Import the new component

export default {
  name: 'TechStackOrganizationsTable',
  components: { VueSlimTables },
  props: { clientId: String },
  data() {
    return {
      search: '',
      showDeleted: true,
      loadingTrelloId: null,
      perPage: 25
    }
  },
  watch: {
    search(_val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.table.refetch, 500)
    },
    showDeleted(_val) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.table.refetch, 500)
    }
  },
  created() {
    this.columns = [
      { title: 'Customer', key: 'customer' },
      { title: 'Statuses', key: 'statuses' },
      { title: 'Moments', key: 'moments' },
      { title: 'Features', key: 'features' },
      { title: 'Actions', key: 'actions' }
    ]
  },
  methods: {
    onStateUpdated() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.$refs.table.refetch, 500)
    },

    reload() {
      this.$refs.table.fetchData(this.$refs.table.page)
    },

    onDelete(id) {
      const params = {
        content: 'This will hide the organization. You will be able to restore it later.'
      }

      runSwal(() => {
        axios.delete(`/organizations/${id}`)
        window.location.reload()
      }, params)
    },

    viewOnboardingNotes(id, name) {
      const slidePanel = useSlidePanel()
      slidePanel.open({
        component: OnboardingNotesComponent,
        props: { id, name },
        events: {
          stateUpdated: this.onStateUpdated
        }
      })
    },

    openNotesSlideout(organizationId) {
      const slidePanel = useSlidePanel()
      slidePanel.open({
        component: OrganizationNotes,
        props: { organizationId },
        events: {
          noteAdded: () => {
            mdtoast.success('Note added successfully', { duration: 3000 })
          }
        }
      })
    },

    updateFeature(organizationId, feature, value) {
      axios.patch(`/ajax/organizations/${organizationId}/update_feature`, {
        feature: feature,
        value: value
      })
        .then((response) => {
          if (response.data.success) {
            mdtoast.success('Updated feature', { duration: 10000 })
            const index = this.$refs.table.items.findIndex((item) => item.id === organizationId)
            if (index !== -1) {
              this.$set(this.$refs.table.items[index], feature, value)
            }
          } else {
            console.error('Failed to update feature:', response.data.message)
          }
        })
        .catch((error) => {
          console.error('Error updating feature:', error)
        })
    },

    onRestore(id) {
      const params = {
        content: 'You will restore the organization and all its users will become active.'
      }

      runSwal(() => {
        axios.put(`/organizations/${id}/restore`)
        window.location.reload()
      }, params)
    },

    getProvisioningBadgeClass(row) {
      const baseClasses = 'px-2 inline-flex text-xs leading-5 font-semibold rounded'
      if (this.isProvisionStatusNA(row)) {
        return `${baseClasses} bg-gray-100 text-gray-800`
      }
      switch (row.provisionStatus) {
        case 'failed':
          return `${baseClasses} bg-red-100 text-red-800`
        case 'in_progress':
          return `${baseClasses} bg-yellow-100 text-yellow-800`
        case 'provisioned':
          return `${baseClasses} bg-green-100 text-green-800`
        default:
          return `${baseClasses} bg-gray-100 text-gray-800`
      }
    },

    initiateTrello(id) {
      this.loadingTrelloId = id
      axios.post(`/organizations/${id}/initiate_trello`)
        .then((response) => {
          if (response.status == 200) {
            mdtoast.success('Trello Initiated', { duration: 5000 })
            this.$refs.table.refetch()
          } else {
            mdtoast.error('Failed to initiate Trello', { duration: 5000 })
          }
        })
        .catch((error) => {
          console.error('Error initiating Trello:', error)
          mdtoast.error('Error initiating Trello', { duration: 5000 })
        })
        .finally(() => {
          this.loadingTrelloId = null
        })
    },

    formatProvisioningStatus(row) {
      if (this.isProvisionStatusNA(row)) {
        return 'N/A'
      }
      switch (row.provisionStatus) {
        case 'failed':
          return 'Failed'
        case 'in_progress':
          return 'In Progress'
        case 'provisioned':
          return 'OK'
        default:
          return 'Unknown'
      }
    },

    isProvisionStatusNA(row) {
      const status = row.provisionStatus
      return status === 'no_billing_plan' || status === 'pending_welcome_form' || status === 'nothing_to_do'
    },

    tableSource(params) {
      return axios.get(
        '/ajax/organizations/tech_stack_customers',
        {
          params: {
            ...params,
            query: this.search,
            per_page: this.perPage,
            show_deleted: this.showDeleted
          },
          paramsSerializer(json) {
            return qs.stringify(json, { arrayFormat: 'brackets' })
          }
        }
      ).then((res) => res.data)
    },

    timeSinceCreated(createdAt) {
      return new Date(createdAt).toLocaleDateString()
    },
    getPlanBadgeClass(plan) {
      if (plan === undefined || plan === null) {
        return 'text-xs font-medium me-2 px-2.5 py-0.5 rounded bg-gray-100 text-gray-800 dark:bg-gray-700 dark:text-gray-300'
      }
      const baseClasses = 'text-xs font-medium me-2 px-2.5 py-0.5 rounded'
      switch (plan.toLowerCase().split('-')[0]) {
        case 'gpp':
          return `bg-blue-100 text-blue-800 ${baseClasses} dark:bg-blue-900 dark:text-blue-300`
        case 'sitesense':
          return `bg-green-100 text-green-800 ${baseClasses} dark:bg-green-900 dark:text-green-300`
        case 'bundle':
          return `bg-purple-100 text-purple-800 ${baseClasses} dark:bg-purple-900 dark:text-purple-300`
        default:
          return `bg-gray-100 text-gray-800 ${baseClasses} dark:bg-gray-700 dark:text-gray-300`
      }
    },

    calculateCustomerDuration(createdAt) {
      const now = new Date()
      const created = new Date(createdAt)
      const diffTime = Math.abs(now - created)
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24))

      const years = Math.floor(diffDays / 365)
      const months = Math.floor((diffDays % 365) / 30)
      const days = diffDays % 30

      let duration = ''
      if (years > 0) {
        duration += `${years} year${years > 1 ? 's' : ''} `
      }
      if (months > 0) {
        duration += `${months} month${months > 1 ? 's' : ''} `
      }
      if (days > 0 || duration === '') {
        duration += `${days} day${days !== 1 ? 's' : ''}`
      }

      return duration.trim()
    },
    getOnboardingBadgeClass(state) {
      const baseClasses = 'px-2 inline-flex text-xs leading-5 font-semibold rounded'
      switch (state) {
        case 'pending_contact':
          return `${baseClasses} bg-red-100 text-red-800`
        case 'contacted':
          return `${baseClasses} bg-blue-100 text-blue-800`
        case 'onboarded':
          return `${baseClasses} bg-green-100 text-green-800`
        default:
          return `${baseClasses} bg-gray-100 text-gray-800`
      }
    },

    formatPeriod(period) {
      const periods = {
        last_30_days: '30d',
        last_90_days: '90d',
        last_180_days: '180d',
        last_12_months: '12m'
      }
      return periods[period] || period
    },

    formatTimeSinceLastMoment(seconds) {
      if (seconds === null) return 'Never'
      const days = Math.floor(seconds / (24 * 60 * 60))
      if (days < 30) return `${days}d ago`
      const months = Math.floor(days / 30)
      return `${months}m ago`
    },

    getLastMomentClass(seconds) {
      if (seconds === null) return 'text-red-600 font-bold animate-pulse'
      const days = Math.floor(seconds / (24 * 60 * 60))
      if (days < 7) return 'text-green-600'
      if (days < 30) return 'text-yellow-600'
      return 'text-red-600'
    },

    formatOnboardingState(state) {
      return state.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    },

    getSentimentBadgeClass(sentiment) {
      const baseClasses = 'px-2 inline-flex text-xs leading-5 font-semibold rounded';
      switch (sentiment) {
        case 'happy':
          return `${baseClasses} bg-green-100 text-green-800`
        case 'ok':
          return `${baseClasses} bg-yellow-100 text-yellow-800`
        case 'upset':
          return `${baseClasses} bg-red-100 text-red-800`
        default:
          return `${baseClasses} bg-gray-100 text-gray-800`
      }
    },

    formatSentiment(sentiment) {
      return sentiment.charAt(0).toUpperCase() + sentiment.slice(1)
    },

    toggleSentimentDropdown(row) {
      row.showSentimentDropdown = !row.showSentimentDropdown
    },

    updateSentiment(row) {
      axios.patch(`/ajax/organizations/${row.id}/update_sentiment`, {
        sentiment: row.sentiment
      })
      .then((response) => {
        if (response.data.success) {
          mdtoast.success('Updated sentiment', { duration: 5000 })
          row.showSentimentDropdown = false
        } else {
          console.error('Failed to update sentiment:', response.data.message)
          mdtoast.error('Failed to update sentiment', { duration: 5000 })
        }
      })
      .catch((error) => {
        console.error('Error updating sentiment:', error)
        mdtoast.error('Error updating sentiment', { duration: 5000 })
      })
    },

    getOrderSubmittedBadgeClass(orderSubmitted) {
      const baseClasses = 'px-2 inline-flex text-xs leading-5 font-semibold rounded';
      return orderSubmitted
        ? `${baseClasses} bg-green-100 text-green-800`
        : `${baseClasses} bg-red-100 text-red-800`;
    },

    formatOrderSubmitted(orderSubmitted) {
      return orderSubmitted ? 'Yes' : 'No';
    },

    toggleOrderSubmittedDropdown(row) {
      row.showOrderSubmittedDropdown = !row.showOrderSubmittedDropdown;
    },

    updateOrderSubmitted(row) {
      debugger
      axios.patch(`/ajax/organizations/${row.id}/update_order_submitted`, {
        order_submitted: row.orderSubmitted
      })
      .then((response) => {
        if (response.data.success) {
          debugger
          mdtoast.success('Updated order submitted status', { duration: 5000 });
          row.showOrderSubmittedDropdown = false;
        } else {
          console.error('Failed to update order submitted status:', response.data.message);
          mdtoast.error('Failed to update order submitted status', { duration: 5000 });
        }
      })
      .catch((error) => {
        console.error('Error updating order submitted status:', error);
        mdtoast.error('Error updating order submitted status', { duration: 5000 });
      });
    },

  }
}
</script>
<!-- eslint-enable no-console-->

<style scoped>
@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>
