<template>
  <div
    :id="id"
    tabindex="-1"
    aria-hidden="true"
    class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50
      justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div class="relative p-4 w-full max-w-2xl max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <slot name="modal-header">
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              <slot name="modal-header-text" />
            </h3>
          </slot>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8
              ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="hide">
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14">
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-4 md:p-5 space-y-4">
          <slot name="modal-body" />
        </div>
        <!-- Modal footer -->
        <slot name="modal-footer-wrap">
          <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 gap-3">
            <slot name="modal-footer">
              <button
                type="button"
                class="btn btn-primary btn-small"
                @click="hide">
                I accept
              </button>
              <button
                type="button"
                class="btn btn-default btn-small"
                @click="hide">
                Decline
              </button>
            </slot>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { Modal } from 'flowbite'

const emits = defineEmits(['onHide', 'onShow'])
const props = defineProps({
  id: { type: String, required: true }
})

let modal = null

const show = () => {
  modal.show()
}

const hide = () => {
  modal.hide()
}

const toggle = () => {
  modal.toggle()
}

defineExpose({ show, hide, toggle })

onMounted(() => {
  modal = new Modal(document.querySelector(`#${props.id}`), {
    onHide() {
      emits('onHide')
    },
    onShow() {
      emits('onShow')
    }
  })
})
</script>
