import { Controller } from '@hotwired/stimulus'
import { createApp } from 'vue'
import axios from '~/init/axios.js'
import '@dmuy/toast/dist/mdtoast.css'
import SubscriptionDescription from '~/vue_widgets/subscriptions/subscription_description.vue'

const FORMATTER = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export default class extends Controller {
  static targets = [
    'amount',
    'formLocationCount',
    'formPlan',
    'interval',
    'locationSelect',
    'planName',
    'locationMgmtAttribute',
    'reputationMgmtAttribute',
    'leadMgmtAttribute',
    'chatMgmtAttribute',
    'automateMgmtAttribute',
    'listingMgmtAttribute'
  ]

  static values = {
    locationCount: { type: Number, default: 1 },
    perLocationCost: Number,
    totalCost: Number,
    plans: Array,
    selectedPlanName: String,
    selectedPlanAmountWithCurrency: String,
    selectedPlanInterval: String
  }

  connect() {
    const props = {
      selectedPlan: {
        name: this.selectedPlanNameValue,
        amountWithCurrency: this.selectedPlanAmountWithCurrencyValue,
        interval: this.selectedPlanIntervalValue
      }
    }

    this.vue = createApp(SubscriptionDescription, props)
    this.vue.mount('#vue-subscription-description')
  }

  disconnect() {
    this.vue?.unmount()
  }

  async getTotalPrice() {
    const plan = this.getPlanByName(this.selectedPlanNameValue)
    if (!Object.keys(plan.graduated_price).length) {
      return plan.amount * this.locationCountValue / 100
    }

    const res = await axios.get('/ajax/plans/price', {
      params: {
        name: this.selectedPlanNameValue,
        count: this.locationCountValue
      }
    })

    return parseInt(res.data.totalCost, 10) / 100
  }

  async changeLocationCount(evt) {
    evt.preventDefault()
    this.locationCountValue = evt.target.value

    this.totalCostValue = await this.getTotalPrice()
    this.amountTarget.innerText = `${FORMATTER.format(this.totalCostValue)}`
    this.formLocationCountTarget.value = evt.target.value
  }

  async changePlan(evt) {
    evt.preventDefault()

    // update plan name
    this.selectedPlanNameValue = evt.target.value

    // disable location count select
    const locationCountSelectWrapper = document.querySelector('#location-counter-select-wrapper')
    if (this.selectedPlanNameValue.toLowerCase().includes('lindabot')) {
      locationCountSelectWrapper.style.visibility = 'hidden'
    } else {
      locationCountSelectWrapper.style.visibility = ''
    }

    // update hidden plan input
    this.formPlanTarget.value = this.selectedPlanNameValue

    // reset location count to 1
    this.locationCountValue = 1
    this.locationSelectTarget.value = 1

    // reset the plan name label
    this.planNameTarget.innerText = `${evt.target.value}`

    // recalc total cost and update labels
    this.totalCostValue = await this.getTotalPrice()
    this.amountTarget.innerText = `${FORMATTER.format(this.totalCostValue)}`
    this.intervalTarget.innerText = this.getPlanByName(evt.target.value).interval
  }

  getPlanByName(planName) {
    return this.plansValue.find(({ name }) => name === planName)
  }
}
